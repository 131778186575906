import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import configuration from "./configuration";
import role from "./role";
import user from "./user";
import actionLog from "./action-log";
import category from "./category";
import bannerPosition from "./banner-position";
import banner from "./banner";
import pictureAlbum from "./picture-album";
import picture from "./picture";
import attribute from "./attribute";
import attributeValue from "./attribute-value";
import template from "./template";
import industryNews from "./industry-news";
import media from "./media";
import book from "./book";
import files from "./files";
import document from "./document";
import documentAuthor from "./document-author";
import documentProgram from "./document-program";
import documentRecord from "./document-record";
import industryCategory from "./industry-category";
import keyword from "./keyword";
import member from "./member";
import program from "./program";
import activity from "./activity";
import publication from "./publication";
import speaker from "./speaker";
import session from "./session";
import sessionDiscount from "./session-discount";
import agenda from "./agenda";
import attend from "./attend";
import attendMember from "./attend-member";
import qnr from "./qnr";
import question from "./question";
import option from "./option";
import coupon from "./coupon";
import rights from "./rights";
import memberRights from "./member-rights";
import order from "./order";
import team from "./team";
import researchCategory from "./research-category";
import couponSet from "./coupon-set";
import couponLog from "./coupon-log";
import couponCategory from "./coupon-category";
import payment from "./payment";
import paymentRecord from "./payment-record";
import consultCategory from "./consult-category";
import suggestion from "./suggestion";
import feedback from "./feedback";
import epaper from "./epaper";
import mailLog from "./mail-log";
import attendDetail from "./attend-detail";
import unit from "./unit";
import survey from "./survey";
import surveyDetail from "./survey-detail";
import attendMemberQuestion from "./attend-member-question";
import utm from "./utm";
import utmCategory from "./utm-category";
import report from "./report";
import certificate from './certificate';
import estateCategory from './estate-category';
import technicalCategory from './technical-category'
import directorSection from './director-section';
import directorSectionCategory from './director-section-category';
import bulletin from './bulletin';
import bulletinCategory from './bulletin-category';
import questionInfo from './question-info';
import questionInfoCategory from './question-info-category';

const model = new Model("EmptyNext", {
  main,
  configuration,
  role,
  user,
  actionLog,
  category,
  bannerPosition,
  banner,
  pictureAlbum,
  picture,
  attribute,
  attributeValue,
  template,
  industryNews,
  media,
  book,
  files,
  document,
  documentAuthor,
  documentProgram,
  documentRecord,
  industryCategory,
  keyword,
  member,
  program,
  activity,
  publication,
  speaker,
  session,
  sessionDiscount,
  agenda,
  attend,
  attendMember,
  qnr,
  question,
  option,
  coupon,
  rights,
  memberRights,
  order,
  team,
  researchCategory,
  couponSet,
  couponLog,
  couponCategory,
  payment,
  paymentRecord,
  consultCategory,
  suggestion,
  feedback,
  epaper,
  mailLog,
  attendDetail,
  unit,
  survey,
  surveyDetail,
  attendMemberQuestion,
  utm,
  utmCategory,
  report,
  certificate,
  estateCategory,
  technicalCategory,
  directorSection,
  directorSectionCategory,
  bulletin,
  bulletinCategory,
  questionInfo,
  questionInfoCategory,
});

const backendServer = process.env.VUE_APP_BACKEND_URL;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = data =>
  model.clients.unauthorized.post("System/Login", {
    account: data.account,
    password: data.password,
    token: data.password,
    LastVisitedUri: data.token,
    LoginIp: data.captcha
  });
model.onLogout = () => model.clients.authorized.post("System/Logout");
model.onReloadUser = () => model.clients.authorized.post("System/CurrentUser");
model.onReloadEnums = () =>
  model.clients.unauthorized.get("System/Enumerations");
model.onLog = message => model.clients.unauthorized.post("System/Log", message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
