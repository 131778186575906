
/* eslint-disable */
import CloudFun, {
  defineComponent,
  ref,
  onMounted,
  PropType
} from "@cloudfun/core";
import FileUpload, { VueUploadItem } from "vue-upload-component";
import { v1 as uuidv1 } from "uuid";

interface FileDto {
  Id: number;
  Name: string;
}

export default defineComponent({
  name: "FileUploadTable",
  components: {
    FileUpload
  },
  props: {
    id: String,
    canDelete: {
      type: Boolean,
      default: true
    },
    canUpload: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    promises: {
      type: Object as PropType<{
        query(): Promise<FileDto[]>;
        insert(data: FormData): Promise<FileDto[]>;
        delete(id: number): Promise<void>;
      }>,
      required: true
    },
    title: String,
    validExt: {
      type: Array
      // default: () => ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.zip', '.rar', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls', '.csv', '.txt', '.pdf']
    },
    validSize: {
      // Bytes
      type: Number,
      default: 25 * 1024 * 1024
    }
  },
  setup(props) {
    const uuid = ref(props.id);
    const uploadFiles = ref<VueUploadItem[]>([]);
    const fileUpload = ref({});
    const data = ref<FileDto[]>();

    onMounted(() => {
      uuid.value = uuid.value || uuidv1();
    });
    const refresh = async () => {
      try {
        data.value = await props.promises?.query();
      } catch (error: any) {
        data.value = [];
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error
        });
      }
    };
    onMounted(() => {
      refresh();
    });

    const upload = () => {
      if (uploadFiles.value.length === 0) return;
      const formData = new FormData();
      for (const file of uploadFiles.value) {
        if (!file.size || !file.name || !file.file) return;
        if (file.size >= props.validSize) {
          CloudFun.send("error", {
            subject: "附件上傳失敗！",
            content: "檔案大小不得超過25M"
          });
          uploadFiles.value = [];
          return;
        }

        const ext = "." + file.name.split(".")?.[1];
        if (props.validExt && props.validExt.indexOf(ext) === -1) {
          uploadFiles.value = [];
          CloudFun.send("error", {
            subject: "附件上傳失敗！",
            content: "不支援此檔案類型"
          });
          return;
        }
        formData.append("files", file.file);
      }

      props.promises
        ?.insert(formData)
        .then(
          () => {
            CloudFun.send("info", { subject: "執行成功", content: "上傳完成" });
            refresh();
          },
          failure =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {
          uploadFiles.value = [];
        });
    };

    const download = (id: number) => {
      window.open(
        `${process.env.VUE_APP_BACKEND_URL}/api/files/download/${id}`
      );
    };

    const remove = (id: number) => {
      props.promises?.delete(id).then(
        () => refresh(),
        failure =>
          CloudFun.send("error", { subject: "操作失敗！", content: failure })
      );
    };

    return {
      uuid,
      fileUpload,
      data,
      uploadFiles,
      refresh,
      upload,
      download,
      remove
    };
  },
  methods: {
    getData() {
      return this.data;
    }
  }
});
