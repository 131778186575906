import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "HomeIcon",
      to: "dashboard",
      title: "儀錶板"
    },
    // {
    //   icon: "fa-columns",
    //   title: "選單安排",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "/dashboard",
    //       title: "側邊選單",
    //       ignore: true
    //     },
    //     {
    //       icon: "",
    //       to: "/simple-menu/dashboard",
    //       title: "簡易選單",
    //       ignore: true
    //     },
    //     {
    //       icon: "",
    //       to: "/top-menu/dashboard",
    //       title: "上方選單",
    //       ignore: true
    //     }
    //   ]
    // },
    {
      icon: "SettingsIcon",
      to: "configuration",
      title: "系統配置"
    },
    {
      icon: "ActivityIcon",
      to: "action-log",
      title: "操作紀錄"
    },
    {
      icon: "fa-file-code",
      to: "mail-log",
      title: "郵件紀錄"
    },
    // {
    //   icon: 'UserIcon',
    //   title: '研究團隊',
    //   subNodes: [
    //     {
    //       icon: "UserIcon",
    //       to: "team",
    //       title: "研究團隊"
    //     },
    //     {
    //       icon: "fa-sitemap",
    //       to: "research-category",
    //       title: "研究領域"
    //     },
    //   ]
    // },
    // {
    //   icon: "fa-file",
    //   title: "研究任務管理",
    //   subNodes: [
    //     {
    //       icon: "fa-sitemap",
    //       to: "program",
    //       title: "產業類別管理"
    //     },
    //     {
    //       icon: "fa-file",
    //       to: "document",
    //       title: "任務管理"
    //     },
    //     {
    //       icon: "fa-eye",
    //       to: "changing",
    //       title: "變更審核管理"
    //     },
    //     {
    //       icon: "fa-feather-alt",
    //       to: "document/report",
    //       title: "撰稿"
    //     },
    //     {
    //       icon: "fa-check",
    //       to: "document/proof",
    //       title: "校稿"
    //     },
    //     {
    //       icon: "fa-file-signature",
    //       to: "document/review",
    //       title: "審稿"
    //     },
    //     {
    //       icon: "fa-file",
    //       to: "publication",
    //       title: "出版品管理"
    //     },
    //     {
    //       icon: "fa-comments",
    //       to: "feedback",
    //       title: "迴響資料"
    //     },
    //   ]
    // },
    {
      icon: "fa-list",
      to: "introduction",
      title: "關於協會",
      subNodes: [
        {
          icon: "fa-file",
          to: "charter-file",
          title: "協會章程"
        },
        {
          icon: "fa-file",
          to: "payment-file",
          title: "繳費說明"
        },
        // {
        //   icon: "fa-list",
        //   to: "DM",
        //   title: "DM"
        // },
        {
          icon: "fa-list",
          to: "introduction",
          title: "本會簡介"
        },
        {
          icon: "fa-list",
          to: "charter",
          title: "本會章程"
        },
        {
          icon: "fa-list",
          to: "organizational-system",
          title: "組織體系"
        },
        {
          icon: "fa-list",
          to: "join",
          title: "加入會員說明"
        }
        // {
        //   icon: "fa-list",
        //   to: "field",
        //   title: "研究領域介紹"
        // }
      ]
    },
    {
      icon: "UserIcon",
      title: "用戶管理",
      subNodes: [
        // {
        //   icon: "fa-magic",
        //   to: "user-wizard",
        //   title: "用戶註冊精靈"
        // },
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色管理"
        },
        {
          icon: "UserIcon",
          to: "user",
          title: "用戶管理"
        }
      ]
    },
    {
      icon: "UserIcon",
      title: "會員管理",
      subNodes: [
        {
          icon: "UserIcon",
          to: "member",
          title: "個人會員管理"
        },
        {
          icon: "UserIcon",
          to: "member-group",
          title: "團體會員管理"
        },
        // {
        //   icon: 'fa-sitemap',
        //   to: 'industry-category',
        //   title: '行業別'
        // },
        {
          icon: "fa-sitemap",
          to: "certificate",
          title: "證照管理"
        },
        {
          icon: "fa-sitemap",
          to: "estate-category",
          title: "產業類別管理"
        },
        {
          icon: "fa-sitemap",
          to: "technical-category",
          title: "技術類別管理"
        }
        // {
        //   icon: "fa-bars",
        //   to: "rights",
        //   title: "會員權益"
        // },
        // {
        //   icon: "fa-bars",
        //   to: "coupon-category",
        //   title: "優惠類別管理"
        // },
        // {
        //   icon: "fa-bars",
        //   to: "coupon-set",
        //   title: "優惠管理"
        // },
      ]
    },
    {
      icon: "fa-table",
      to: "activity",
      title: "活動管理",
      subNodes: [
        {
          icon: "fa-table",
          to: "activity",
          title: "活動管理"
        },
        {
          icon: "fa-users",
          to: "unit",
          title: "活動機關管理"
        },
        {
          icon: "fa-users",
          to: "speaker",
          title: "演講人管理"
        },
        // {
        //   icon: "fa-search",
        //   to: "qnr",
        //   title: "問卷管理"
        // }
      ]
    },
    // {
    //   icon: "fa-book",
    //   to: "book",
    //   title: "專業書城",
    //   subNodes: [
    //     {
    //       icon: "fa-book",
    //       to: "book",
    //       title: "專業書城"
    //     },
    //     {
    //       icon: "fa-book",
    //       to: "storeDM",
    //       title: "書單下載"
    //     }
    //   ]
    // },
    // {
    //   icon: "fa-envelope",
    //   to: "consult-category",
    //   title: "服務管理",
    //   subNodes: [
    //     {
    //       icon: "fa-envelope",
    //       to: "consult-category",
    //       title: "諮詢類別管理"
    //     },
    //     {
    //       icon: "fa-envelope",
    //       to: "suggestion",
    //       title: "客服信箱管理"
    //     },
    //   ]
    // },
    {
      icon: "fa-newspaper",
      to: "epaper",
      title: "電子報管理"
    },
    // {
    //   icon: "fa-shopping-cart",
    //   to: "order",
    //   title: "訂單管理"
    // },
    {
      icon: "fa-list",
      to: "template",
      title: "樣板管理"
    },
    {
      icon: "FlagIcon",
      to: "banner",
      title: "廣告管理"
    },
    {
      icon: "ImageIcon",
      to: "album",
      title: "活動集錦"
    },
    // {
    //   icon: "fa-file-audio",
    //   to: "media",
    //   title: "影音專區"
    // },
    // {
    //   icon: "fa-newspaper",
    //   to: "industry-news",
    //   title: "產業新聞"
    // },
    // {
    //   icon: "fa-keyboard",
    //   to: "keyword",
    //   title: "關鍵字管理"
    // },
    // {
    //   icon: 'fa-link',
    //   title: '廣宣來源管理',
    //   subNodes: [
    //     {
    //       icon: "fa-link",
    //       to: "utm-category",
    //       title: "廣宣類別管理"
    //     },
    //     {
    //       icon: "fa-link",
    //       to: "utm",
    //       title: "廣宣來源"
    //     },
    //   ]
    // },
    {
      icon: "fa-list",
      to: "director-section",
      title: "理監事專區",
      subNodes: [
        {
          icon: "fa-sitemap",
          to: "director-section-category",
          title: "類別管理"
        },
        {
          icon: "fa-list",
          to: "director-section",
          title: "訊息管理"
        }
      ]
    },
    {
      icon: "fa-list",
      to: "meeting-news",
      title: "會訊文章",
      subNodes: [
        {
          icon: "fa-sitemap",
          to: "meeting-news-category",
          title: "類別管理"
        },
        {
          icon: "fa-list",
          to: "meeting-news",
          title: "訊息管理"
        }
      ]
    },
    {
      icon: "fa-list",
      to: "news",
      title: "訊息管理",
      subNodes: [
        {
          icon: "fa-list",
          title: "最新消息",
          subNodes: [
            { icon: "fa-sitemap", to: "news-category", title: "類別管理" },
            { icon: "fa-list", to: "news", title: "訊息管理" }
          ]
        },
        {
          icon: "fa-list",
          title: "會務訊息",
          subNodes: [
            {
              icon: "fa-sitemap",
              to: "information-category",
              title: "類別管理"
            },
            { icon: "fa-list", to: "information", title: "訊息管理" }
          ]
        },
        {
          icon: "fa-list",
          title: "政令宣導",
          subNodes: [
            { icon: "fa-sitemap", to: "advocacy-category", title: "類別管理" },
            { icon: "fa-list", to: "advocacy", title: "訊息管理" }
          ]
        },
        {
          icon: "fa-list",
          title: "問與答",
          subNodes: [
            {
              icon: "fa-sitemap",
              to: "question-info-category",
              title: "類別管理"
            },
            { icon: "fa-list", to: "question-info", title: "訊息管理" }
          ]
        }
      ]
    },
    {
      icon: "fa-file",
      to: "research-process-report",
      title: "報表管理",
      subNodes: [
        // {
        //   icon: "fa-file",
        //   to: "research-process-report",
        //   title: "研究任務追踨統計"
        // },
        {
          icon: "fa-file",
          to: "keyword-statistics-report",
          title: "關鍵字查詢統計"
        },
        // {
        //   icon: "fa-file",
        //   to: "utm-statistics-report",
        //   title: "廣宣來源連結統計"
        // },
        {
          icon: "fa-file",
          to: "visitor-statistics-report",
          title: "訪客瀏覽數統計"
        },
        // {
        //   icon: "fa-file",
        //   to: "publication-statistics-report",
        //   title: "文章點閱及下載次數統計"
        // },
        {
          icon: "fa-file",
          to: "bullerin-statistics-report",
          title: "文章點閱及下載次數統計"
        },
        {
          icon: "fa-file",
          to: "member-rights-statistics-report",
          title: "會員權益購買統計"
        },
      ]
    }
    // {
    //   icon: 'fa-list',
    //   to: 'attribute',
    //   title: '屬性管理'
    // },
    //   'devider',
    //   {
    //     icon: 'fa-tachometer-alt',
    //     to: 'midone-dashboard',
    //     title: 'Dashboard'
    //   },
    //   {
    //     icon: 'InboxIcon',
    //     to: 'midone-inbox',
    //     title: 'Inbox'
    //   },
    //   {
    //     icon: 'HardDriveIcon',
    //     to: 'midone-file-manager',
    //     title: 'File Manager'
    //   },
    //   {
    //     icon: 'CreditCardIcon',
    //     to: 'midone-point-of-sale',
    //     title: 'Point of Sale'
    //   },
    //   {
    //     icon: 'MessageSquareIcon',
    //     to: 'midone-chat',
    //     title: 'Chat'
    //   },
    //   {
    //     icon: 'FileTextIcon',
    //     to: 'midone-post',
    //     title: 'Post'
    //   },
    //   'devider',
    //   {
    //     icon: 'EditIcon',
    //     to: 'midone-crud-data-list',
    //     title: 'Crud',
    //     subNodes: [
    //       {
    //         icon: '',
    //         to: 'midone-crud-data-list',
    //         title: 'Data List'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-crud-form',
    //         title: 'Form'
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'UsersIcon',
    //     to: 'midone-users-layout-1',
    //     title: 'Users',
    //     subNodes: [
    //       {
    //         icon: '',
    //         to: 'midone-users-layout-1',
    //         title: 'Layout 1'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-users-layout-2',
    //         title: 'Layout 2'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-users-layout-3',
    //         title: 'Layout 3'
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'TrelloIcon',
    //     to: 'midone-profile-overview-1',
    //     title: 'Profile',
    //     subNodes: [
    //       {
    //         icon: '',
    //         to: 'midone-profile-overview-1',
    //         title: 'Overview 1'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-profile-overview-2',
    //         title: 'Overview 2'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-profile-overview-3',
    //         title: 'Overview 3'
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'LayoutIcon',
    //     to: 'midone-wizard-layout-1',
    //     title: 'Pages',
    //     subNodes: [
    //       {
    //         icon: '',
    //         to: 'midone-wizard-layout-1',
    //         title: 'Wizards',
    //         subNodes: [
    //           {
    //             icon: '',
    //             to: 'midone-wizard-layout-1',
    //             title: 'Layout 1'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-wizard-layout-2',
    //             title: 'Layout 2'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-wizard-layout-3',
    //             title: 'Layout 3'
    //           }
    //         ]
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-blog-layout-1',
    //         title: 'Blog',
    //         subNodes: [
    //           {
    //             icon: '',
    //             to: 'midone-blog-layout-1',
    //             title: 'Layout 1'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-blog-layout-2',
    //             title: 'Layout 2'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-blog-layout-3',
    //             title: 'Layout 3'
    //           }
    //         ]
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-pricing-layout-1',
    //         title: 'Pricing',
    //         subNodes: [
    //           {
    //             icon: '',
    //             to: 'midone-pricing-layout-1',
    //             title: 'Layout 1'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-pricing-layout-2',
    //             title: 'Layout 2'
    //           }
    //         ]
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-invoice-layout-1',
    //         title: 'Invoice',
    //         subNodes: [
    //           {
    //             icon: '',
    //             to: 'midone-invoice-layout-1',
    //             title: 'Layout 1'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-invoice-layout-2',
    //             title: 'Layout 2'
    //           }
    //         ]
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-faq-layout-1',
    //         title: 'FAQ',
    //         subNodes: [
    //           {
    //             icon: '',
    //             to: 'midone-faq-layout-1',
    //             title: 'Layout 1'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-faq-layout-2',
    //             title: 'Layout 2'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-faq-layout-3',
    //             title: 'Layout 3'
    //           }
    //         ]
    //       },
    //       {
    //         icon: '',
    //         to: 'login',
    //         title: 'Login'
    //       },
    //       {
    //         icon: '',
    //         to: 'register',
    //         title: 'Register'
    //       },
    //       {
    //         icon: '',
    //         to: 'error-page',
    //         title: 'Error Page'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-update-profile',
    //         title: 'Update profile'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-change-password',
    //         title: 'Change Password'
    //       }
    //     ]
    //   },
    //   'devider',
    //   {
    //     icon: 'InboxIcon',
    //     to: 'midone-regular-table',
    //     title: 'Components',
    //     subNodes: [
    //       {
    //         icon: '',
    //         to: 'midone-regular-table',
    //         title: 'Table',
    //         subNodes: [
    //           {
    //             icon: '',
    //             to: 'midone-regular-table',
    //             title: 'Regular Table'
    //           },
    //           {
    //             icon: '',
    //             to: 'midone-tabulator',
    //             title: 'Tabulator'
    //           }
    //         ]
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-accordion',
    //         title: 'Accordion'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-button',
    //         title: 'Button'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-modal',
    //         title: 'Modal'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-alert',
    //         title: 'Alert'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-progress-bar',
    //         title: 'Progress Bar'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-tooltip',
    //         title: 'Tooltip'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-dropdown',
    //         title: 'Dropdown'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-toast',
    //         title: 'Toast'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-typography',
    //         title: 'Typography'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-icon',
    //         title: 'Icon'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-loading-icon',
    //         title: 'Loading Icon'
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'SidebarIcon',
    //     to: 'midone-regular-form',
    //     title: 'Forms',
    //     subNodes: [
    //       {
    //         icon: '',
    //         to: 'midone-regular-form',
    //         title: 'Regular Form'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-datepicker',
    //         title: 'Datepicker'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-tail-select',
    //         title: 'Tail Select'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-file-upload',
    //         title: 'File Upload'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-wysiwyg-editor',
    //         title: 'Wysiwyg Editor'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-validation',
    //         title: 'Validation'
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'HardDriveIcon',
    //     to: 'midone-chart',
    //     title: 'Widgets',
    //     subNodes: [
    //       {
    //         icon: '',
    //         to: 'midone-chart',
    //         title: 'Chart'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-slider',
    //         title: 'Slider'
    //       },
    //       {
    //         icon: '',
    //         to: 'midone-image-zoom',
    //         title: 'Image Zoom'
    //       }
    //     ]
    //   }
  ]
};

export default sitemap;
