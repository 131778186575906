
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#2d3748'
    }
  }
})
