<template>
  <router-view />
</template>

<style>
.vxe-header--column .vxe-cell--sort .vxe-sort--asc-btn,
.vxe-header--column .vxe-cell--sort .vxe-sort--desc-btn,
.vxe-header--column .vxe-cell--filter .vxe-filter--btn,
.vxe-icon--arrow-left,
.vxe-icon--d-arrow-left,
.vxe-icon--arrow-right,
.vxe-icon--d-arrow-right {
  border-color: unset !important;
}
.ck-tooltip .ck-tooltip__text {
  color: #fff !important;
}
.vue-tags-input .ti-input {
  border-color: unset !important;
}
.vue-tags-input .ti-input {
  border-color: #ffffff;
  border-radius: 4px !important;
  font-size: unset !important;
  line-height: unset !important;
}
.vue-tags-input .ti-input {
  border-color: #c9d0d8 !important;
  padding: 2px !important;
}
.vue-tags-input.ti-focus .ti-input {
  border-color: rgba(59, 130, 246, 0.7) !important;
}
.vue-tags-input .ti-new-tag-input-wrapper,
.vue-tags-input .ti-tag {
  font-size: unset !important;
}
</style>
